import React from "react"
import classNames from "classnames"

import DropzoneBody from "./UploadDropzoneBody"
import DropzoneLabel from "./UploadDropzoneLabel"

import styles from "../utils/upload.module.scss"

const UploadDropzone = ({
  label,
  icon,
  fileInputRef,
  handleFileChooser,
  handleFileRead,
  notifications = [],
  t,
}) => {
  return (
    <div className={classNames("container mt-1", styles["dropzone"])}>
      <input
        type="file"
        className={styles["dropzone__input"]}
        ref={fileInputRef}
        onChange={handleFileRead}
        multiple
        accept="image/x-png,image/gif,image/jpeg,image/jpg,.pdf"
        value=""
      />

      {notifications.length > 0 && notifications.map((item) => item)}
      <div
        className={classNames(styles["dropzone__container"])}
        onClick={handleFileChooser}
        role="button"
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === "Enter") handleFileChooser()
        }}
      >
        <DropzoneBody icon={icon} label={label} />
        <DropzoneLabel label={label} />
      </div>
    </div>
  )
}

export default UploadDropzone
