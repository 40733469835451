import React from "react"
import { Form, Formik } from "formik"

import Message from "elements/Message"
import FormCheckbox from "../../Elements/Form/FormCheckbox"

import { uploadGuidelinesValidationSchema } from "../utils/uploadGuidelinesValidationSchema"

const UploadGuidelinesModal = ({ handleCloseModal }) => {
  return (
    <Formik
      initialValues={{
        hasCorrectDiagnosis: [],
        hasCorrectDailyIntake: [],
        doesNotIncludeOtherMeds: [],
        doesNotIncludeHalfTablet: [],
        hasAcceptedRequirements: [],
      }}
      validationSchema={uploadGuidelinesValidationSchema}
      onSubmit={handleCloseModal}
    >
      {({ values, errors }) => (
        <Form>
          <div className="content has-text-left">
            <Message color="warning">
              Note: If your prescription file size exceeds 5MB, please take a
              screenshot of the original file and upload it to the website.
            </Message>
            <p>I confirm that my uploaded prescription:</p>
            <div className="ml-2 mb-2">
              <FormCheckbox
                name="hasCorrectDiagnosis"
                options={["Has a diagnosis of heart failure only"]}
                values={values.hasCorrectDiagnosis}
                isRequired={true}
                errors={errors}
                />
              <FormCheckbox
                name="hasCorrectDailyIntake"
                options={[
                  "Indicates a daily intake of twice a day, without exceeding 400 mg/day",
                ]}
                values={values.hasCorrectDailyIntake}
                isRequired={true}
                errors={errors}
                />
              <FormCheckbox
                name="doesNotIncludeOtherMeds"
                options={["Does not include ACE inhibitors and ARBs"]}
                values={values.doesNotIncludeOtherMeds}
                isRequired={true}
                errors={errors}
                />
              <FormCheckbox
                name="doesNotIncludeHalfTablet"
                options={["Does not include “½ tablet” or tablet splitting"]}
                values={values.doesNotIncludeHalfTablet}
                isRequired={true}
                errors={errors}
                />
            </div>
            <FormCheckbox
              name="hasAcceptedRequirements"
              options={[
                "I understand that my order may be cancelled if my prescription does not meet the requirements above.",
              ]}
              values={values.hasAcceptedRequirements}
              isRequired={true}
              errors={errors}
              />
            <div className="buttons is-centered">
              <button className="button is-primary" type="submit">
                Continue
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default UploadGuidelinesModal
