import React from "react"
import classNames from "classnames"

import styles from "../utils/upload.module.scss"

const UploadErrorNotification = ({ fileName, message }) => (
  <div className="notification has-text-centered is-warning">
    {fileName && (
      <span
        className={classNames(
          "has-text-weight-bold is-italic",
          styles["uploadFileName"]
        )}
      >
        {fileName}
      </span>
    )}{" "}
    {message}
  </div>
)

export default UploadErrorNotification
