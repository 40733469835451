import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../Layout/index"
import Upload from "../../Upload"
import EpharmacyCart from "../Cart/index"
import guidelines from "../utils/uploadGuidelines.json"
import { EpharmacyContext } from "../EpharmacyContext/EpharmacyContext"

const EpharmacyUpload = ({ pageContext }) => {
  const { module } = pageContext
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)
  const data = useStaticQuery(graphql`
    {
      document: file(relativePath: { eq: "icons/upload__document.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      prescription: file(
        relativePath: { eq: "icons/upload__prescription.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const fileUploadPrescription = data?.prescription?.childImageSharp?.fixed
  const MAX_FILE_SIZE_IN_BYTES = 5242880
  const MAX_FILE_COUNT = 6

  return (
    <Layout {...module} pageContext={pageContext}>
      <Upload
        pageContext={pageContext}
        icon={fileUploadPrescription}
        maxFileCount={MAX_FILE_COUNT}
        maxFileSize={MAX_FILE_SIZE_IN_BYTES}
        guidelines={guidelines}
        moduleName={module.name}
        cartContainer={
          <EpharmacyCart
            open={{
              documentsCard: true,
              medicinesCard: true,
            }}
            state={epharmacyState}
            dispatch={epharmacyDispatch}
          />
        }
        documents={epharmacyState?.documents}
        medicines={epharmacyState?.medicines}
        dispatch={epharmacyDispatch}
        type={"epharmacy-prescription"}
      />
    </Layout>
  )
}

export default EpharmacyUpload
